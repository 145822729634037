import { Test } from "services/server/functions/model/diagnosis/model/Test";
import Details from "ui/components/Details";
import DetailsBlock from "ui/components/DetailsBlock";
import TestStatusLegend from '../components/TestStatusLegend';
import history from 'history.js';
import {Text} from 'ui/components/Text';
import moment from "moment";
import ReportMessages from "../test/ReportMessages";
import * as Pages from 'modules/diagnosis';

import './viewStudyHelper.css';
import { Study } from "services/server/functions/model/diagnosis/model";

export const getConductedDate = (idx, test) => Study.conductedDate({ [idx]: test })
export const getTestSeverity = (hasAccess, test) => hasAccess(Study.entities.Test.queries.GET_ACUAHI3_SEVERITY) ? test.report?.diagnosis?.['ACU-AHI3']?.severity : hasAccess(Study.entities.Test.queries.GET_ACUAHI4_SEVERITY) ? test.report?.diagnosis?.['ACU-AHI4']?.severity : undefined;

export const TestWarnings = ({test}) => {
  const repeatMessages = (test.repeatReason || []).filter(r => test.report?.code === "AC/000" || r === Test.REPEAT_REASONS.sensorFellOff).map(r => {
    const code = test.report?.code === "AC/000" ? `VALID-${r}` : r;
    return {code: `AC/WARNING/${code}`, text: `module.diagnosis.fe.view.study.repeat_reason_warn.${code}`};
  });
  
  return <ReportMessages.List id={`${test.sequence}-${test.retry}`} messages={ReportMessages.clinicianMessages(test, test?.repeated && repeatMessages.some(m => m.code.includes(Test.REPEAT_REASONS.sensorFellOff)) ? ["AC/WARNING/306", "AC/WARNING/307"] : undefined, repeatMessages)} compact={true} />
}

const getTestSeverityClass = (testSeverity) => `${testSeverity?.replace("/", "_").toLowerCase()}`
  
const getTestStatusStyle = ({ test, testSeverity }) => {
  try {
    
    const testStatusCases = [
     {
        isValid: () => test.status === Test.STATUS.analysed && test?.repeated ,
        className: 'repeated'
      },
      {
        isValid: () => test.status === Test.STATUS.invalid && test.status !== Test.STATUS.analysed,
        className: 'invalid'
      },
      {
        isValid: () => test.status === Test.STATUS.analysed && testSeverity !== undefined,
        className: `${getTestSeverityClass(testSeverity)}`
      },
      {
        isValid: () => !testSeverity && test.status === Test.STATUS.analysed,
        className: 'no-permission'
      }
    ];

    return testStatusCases.find(testStyleCase => testStyleCase.isValid()).className;
  } catch (e) {
    return 'not-analysed';
  }
}

const noApnoeaLevels = (testSeverity) => testSeverity && ["normal", "normal/mild", "mild"].includes(testSeverity.toLowerCase());

const hasApneaLevels = (testSeverity) => testSeverity && ["mild/moderate", "moderate", "moderate/severe", "severe"].includes(
                  testSeverity.toLowerCase());

const getSeverityBlock = ({ id, idx, test, testSeverity }) => {
  const testStatusCaseStyle = getTestStatusStyle({
    test,
    testSeverity,
  });
  
  return (
    <div
      id="testSeverityField"
      onClick={(_) =>
        test.status !== Test.STATUS.cancelled && history.push(
          Pages.ViewTest.PATH,
          { study: id, test: `${idx}`, retry: test.retry }
        )
      }
      className={`testSummary ${
        test.status !== Test.STATUS.cancelled ? "selectable" : ""
      } ${testStatusCaseStyle}`}
    >
      <span className="vcentered centered">
        {
        test.status === Test.STATUS.analysed && test?.repeated ? Text.resolve(`module.diagnosis.fe.view.study.repeat_reason_summary.${test.repeatReason?.[0] || Test.REPEAT_REASONS.sensorFellOff}`) :
        testSeverity &&
        test.status === Test.STATUS.analysed && noApnoeaLevels(testSeverity)
          ? Text.resolve("ViewTest.summary.diagnosis.no-apnoea")
          : testSeverity &&
            test.status === Test.STATUS.analysed && hasApneaLevels(testSeverity)
          ? Text.resolve("The patient has %{severity} sleep apnoea", {
              severity: Text.resolve(testSeverity),
            })
          : !testSeverity &&
            test.status === Test.STATUS.analysed 
          ? Text.resolve("ViewStudy.summary.no-diagnosis-permission")
          : test.status === Test.STATUS.pending
          ? Text.resolve("page.study.not_started_test_message")
          : test.status === Test.STATUS.started
          ? Text.resolve("ViewStudy.summary.started-test")
          : test.status === Test.STATUS.conducted
          ? Text.resolve("ViewStudy.summary.conducted-test")
          : test.status === Test.STATUS.cancelled
          ? Text.resolve("CANCELLED-DESC")
          : Text.resolve("ViewTest.summary.diagnosis.insufficient-info")}
      </span>
    </div>
  ) 
};

const getSleepContentBlock = ({ id, idx, test, conductedDate, testSeverity  }) => {
  const testNumber = parseInt(test?.sequence || idx);
  const night = testNumber + 1;
  const hasRepeatedTest = test?.repeated;
  const statusClass = hasRepeatedTest ? 'REPEATED' : Test.getStatusGroup(test.status);

  return  (
    <>
      <Details
        detailId="testNightField"
        className="vcentered"
        label="ViewStudy.night"
        value={`${test?.retry ? `${night} [R${test?.retry}]`: night}`}
      />
      <Details
        detailId="testStatusField"
        className="vcentered"
        label="ViewStudy.status"
        help={<TestStatusLegend />}
      >
        <div className={`status ${statusClass}`}>
          <Text context={{ schema: "diagnosis.Study.Test" }}>
            { hasRepeatedTest ? 'module.diagnosis.fe.study.repeated_label' : Test.getStatusGroup(test.status)}
          </Text>
        </div>
      </Details>
      {getSeverityBlock({
        id,
        idx,
        test,
        testSeverity
      })}
      <Details
        detailId="testConductedField"
        className="vcentered"
        label={Text.resolve("ViewStudy.test.conducted-date")}
        value={
          conductedDate && test.status !== Test.STATUS.cancelled
            ? moment(conductedDate).utcOffset(conductedDate).format("L")
            : "-"
        }
      />
      {test.status !== Test.STATUS.cancelled ? <TestWarnings test={test}/> : <></>}
      {test.status !== Test.STATUS.cancelled ? (
        <Pages.ViewTest.AsLinkButton
          id="testViewField"
          linkClass="right centered vcentered"
          className={
            Test.STATUS.compare(test.status, test.status === Test.STATUS.analysed) >= 0
              ? "primary"
              : "secondary"
          }
          text="view"
          study={id}
          test={`${testNumber}`}
          retry={test?.retry}
        />
      ) : (
        <div id="testViewField"></div>
      )}
    </>
  )
};

export const getSleepStudyBlock = ({
  id,
  idx,
  test,
  conductedDate,
  testSeverity,
  hasAccess
}) => {
  const hasRetriesTest = test?.repeats;
  const blockStatus    = hasRetriesTest ? Object.values(test.repeats).pop().status : test.status;
  const repeats = test.repeats && Object.values(test.repeats);

  return (
    <DetailsBlock
      key={`test${idx}`}
      inline
      className={`testBlock ${blockStatus}`}
    >
      <div className="viewStudy-helper">
        <div className="test-block content">
          {getSleepContentBlock({ id, idx, conductedDate, testSeverity, test })}
        </div>

        {hasRetriesTest &&
          repeats.map(retryTest => {
            const retryConductedDate = getConductedDate(retryTest.retry, retryTest);
            const retryTestSeverity = getTestSeverity(hasAccess, retryTest);
            
            return (
              <div className="test-block-margin" key={`retry_${retryTest.retry}`}>
                <div className="retries-block-title">
                  <span className={`dashed dashed-color-${blockStatus}`}/>
                  <span className={`dashed-title dashed-title-color-${blockStatus}`}> {Text.resolve('module.diagnosis.fe.view.study.repeat_title')}</span>
                  <span className={`dashed dashed-color-${blockStatus}`}/>
               </div>
                <div className="test-block content">
                  {getSleepContentBlock({
                    id,
                    idx,
                    conductedDate: retryConductedDate,
                    testSeverity : retryTestSeverity,
                    test         : retryTest
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </DetailsBlock>
  );
};
