import { Authenticator } from '.';

export default { // ordered list of event transformers (most recent versions at the end of array)
  1649282400000: async (event) => { // 2021.04.06-1.6.27

    if (event.aggregate.name === Authenticator.name && Array.isArray(event.data.tokens)) {
      event.data.tokens = event.data.tokens.reduce((tokens, token) => ({...tokens, [token.value]: token}), {});
    }

    return event;
  },
  1610236800000: async (event) => { // 2021.01.10-1.1.0

    if (event.aggregate.name === Authenticator.name && 'token' in (event.data || {})) {
      event.data.tokens = [event.data.token];
      delete event.data.token;
    }

    return event;
  }
}