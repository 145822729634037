
import { FORBIDDEN_POLICY, FORBIDDEN_USER_EDIT_POLICY, UNIQUE_FIELD_POLICY, when } from '../../policies';
import { Joi, list, mail, min, phone, unique } from '../../../validation/rules';
import { PHONE_REQUIRED_POLICY, checkMailDoesNotExist, checkPhoneDoesNotExist, hasAssignedStudies, checkNewUserRoles, userEmailAlreadyExists } from './policies';
import { hasAllRoles, allOwners } from '../../../iam';
import { isBrowser } from '../../../env';

import Config from '../config';

import { Roles } from '../../../iam/roles';
import { withDefaults } from '../..';

const STATUS = { new: "NEW", active: "ACTIVE", disabled: "DISABLED" };

const notBelongs = async (currentUser, owners) => (owners || []).some(o => !currentUser.data.owners.includes(o)) // This is just a shortcut to avoid processing next promise in some cases (all owners are a subset of current user direct owners)
                                               && (await Promise.all(owners.map(allOwners))).some(allOwns => allOwns.every(o => !currentUser.data.owners.includes(o)));

const isOwnerOfSignature = (currentUser, signatureUserId) => currentUser.isSuperAdmin() || signatureUserId === currentUser.aggregate.id;

let User = {
  context: Config.context,
  name   : 'User',
  STATUS,
  isOwnerOfSignature,
  schema : Joi.object().keys({
    mail         : mail,
    emailVerified: Joi.boolean(),
    phone        : Joi.alternatives().try(phone.when('preferences.authentication.twoFactorEnabled', { is: true, then: Joi.required() }), Joi.string().allow(null)),
    phoneVerified: Joi.boolean(),
    name         : Joi.string(),
    lastName     : Joi.string(),
    fullName     : Joi.string(),
    job          : Joi.string(),
    roles        : unique(min(list(Joi.string()), 1)),
    status       : Joi.string().valid(...Object.values(STATUS)),
    preferences  : Joi.object().keys({
      authentication: Joi.object().keys({
        twoFactorEnabled: Joi.boolean(),
      }),
      notifications: Joi.object().keys({
        emailEnabled: Joi.boolean(),
      })
    }),
    apiKeys      : Joi.array().items(Joi.string()),
  }),
  entities: {
    get Preferences() {
      delete this.Preferences;
      this.Preferences = require('../../system/model').Preferences(User);
      return this.Preferences;
    },
  },
  defaultFrom: ({uid, email, phoneNumber}) => ({
    context  : {name: User.context},
    aggregate: {name: User.name, id: uid},
    data     : { id: uid, roles: [], owners: [], mail: email, phone: phoneNumber },
    metadata : {allOwners: [], allRoles: []} // TODO: try to remove allRoles metadata (only required by Firestore/Storage secrity rules)
  }),
  events: {
    USER_REGISTERED      : { label: 'User created' },
    USER_DISABLED        : { },
    USER_ENABLED         : { },
    USER_UPDATED         : { },
    USER_MAIL_UPDATED    : { },
    USER_PHONE_UPDATED   : { },
    EMAIL_VERIFIED       : { snapshot: () => ({ data: {emailVerified: true} }) },
    USER_UNREGISTERED    : { label: 'User deleted', snapshot: () => ({ metadata: { deleted: true } }) },
    IMPERSONATOR_DELETED : { snapshot: () => ({ metadata: { deleted: true }}) },
    ROLE_ASSIGNED        : { },
    USER_AUTHENTICATED   : { },
    USER_LOGOUT          : { },
    API_KEY_GENERATED    : { snapshot: (event, prevUser) => {
      const apiKeys = prevUser.data.apiKeys || [];
      apiKeys.push(event.data.apiKeys[0]);
      return { data: { apiKeys } };
    } },
    SIGNATURE_SET        : {},
    SIGNATURE_DELETED    : {},
  },
  checkPolicies: (user, _, executor) => Promise.all([
    // FIXME: isBrowser is used as a workaround for those cases where the user is allowed to execute a command (like EDIT_USER_MAIL) but not to execute query MAIL_EXISTS: need to think in a general approach
    (!isBrowser() && user?.data.mail ) ? checkMailDoesNotExist(user.data, executor) : Promise.resolve(true),
    (!isBrowser() && user?.data.phone) ? checkPhoneDoesNotExist(user.data, executor) : Promise.resolve(true),
    when(Boolean(user && !user.data.phone && user.data.preferences?.authentication?.twoFactorEnabled)).rejectWith(PHONE_REQUIRED_POLICY()),
  ]),
  snapshot: (event, prevUser) => {
    const user = {data: {}};
    if (Array.isArray(event.data.roles) && event.data.roles.length) 
      user.metadata = { allRoles: Roles.expand(event.data.roles) };
    
    const disabled = (prevUser?.data.status === User.STATUS.disabled || event.type === User.events.USER_DISABLED.type) && event.type !== User.events.USER_ENABLED.type;
    const active   = prevUser?.data.status === User.STATUS.active || event.type === User.events.EMAIL_VERIFIED.type || (prevUser?.data.status === User.STATUS.disabled && event.type === User.events.USER_ENABLED.type);

    user.data.status = disabled ? User.STATUS.disabled 
    : active ? User.STATUS.active 
    : User.STATUS.new;

    if (event.data.name && event.data.lastName) {
      user.data.fullName = `${event.data.name} ${event.data.lastName}`;
    } else if (event.data.fullName) delete event.data.fullName;

    return user;
  },
  commands: {
    REGISTER_USER: {
      checkPolicies: (request, prevUser, _exec, {user: currentUser}) => Promise.all([
        when(prevUser !== undefined && !prevUser.metadata?.deleted).rejectWith(UNIQUE_FIELD_POLICY(`User unique identifier already in use.`)),
        when(request.preferences?.authentication?.twoFactorEnabled === true).rejectWith(FORBIDDEN_POLICY("User can not be created with MFA enabled, this setting can only be enabled by the own user")),
        when(!currentUser.isSuperAdmin() && notBelongs(currentUser, request.owners)).rejectWith(FORBIDDEN_POLICY("User can not be assigned to an unknown owner (owner might not exist or current user might not have required ownership)")),
        checkNewUserRoles(currentUser, request),
        when(!hasAllRoles(request.roles)(currentUser.data.roles)).rejectWith(FORBIDDEN_POLICY("User can not be assigned to an unknown role")),
        userEmailAlreadyExists(_exec, request.mail),
      ]),
      label : "global.user.create",
      get schema() { 
        delete this.schema;
        this.schema = User.schema.fork(['id', 'mail', 'job', 'roles', 'owners'], schema => schema.required())
                          .fork(['name', 'lastName'], schema => schema.required().trim().min(1))
                         .fork(['emailVerified'], schema => schema.forbidden()) // TODO: some attributes like emailVerified, or phoneVerified should be derived and not be allowed to edit them from a request
                         .fork(['preferences.notifications.emailEnabled'], s => s.default(false));
        return this.schema;
      },
      get event () { delete this.event; this.event =User.events.USER_REGISTERED; return this.event; }
    },
    DELETE_IMPERSONATOR: {
      get schema() { delete this.schema; this.schema = Joi.object().keys({id: User.schema.extract('id').required()}); return this.schema; },
      get event () { delete this.event; this.event = User.events.IMPERSONATOR_DELETED; return this.event; }
    },
    DISABLE_USER: {
      get schema() { 
        delete this.schema;
        this.schema = Joi.object().keys({
          id: User.schema.extract('id').required(),
          notifyUser: Joi.boolean().default(false)
        });
        return this.schema;
      },
      get event () { return User.events.USER_DISABLED; }
    },
    ENABLE_USER: {
      get schema() { 
        return Joi.object().keys({
          id: User.schema.extract('id').required(),
          notifyUser: Joi.boolean().default(false)
        });
      },
      get event () { return User.events.USER_ENABLED }
    },
    UNREGISTER_USER: {
      label : "global.user.delete",
      checkPolicies: (user, _, executor) => when(hasAssignedStudies(user, executor)).rejectWith(FORBIDDEN_POLICY("User has studies assigned, please transfer them and try again")),
      get schema() { return Joi.object().keys({ id: User.schema.extract('id').required() }); },
      get event () { return User.events.USER_UNREGISTERED; }
    },
    UPDATE_USER_MAIL: { // FIXME: create field-level permissions and substitute this by a more generic edit field command (maybe reuse UPDATE_USER but this is less informative from an event/auditlog point of view)
      checkPolicies: (request, _1, _2, {user: currentUser}) => when(!currentUser.isSuperAdmin() && currentUser.data.id !== request.id).rejectWith(FORBIDDEN_USER_EDIT_POLICY()),
      get schema() { 
        return Joi.object().keys({
          id: User.schema.extract('id').required(),
          mail: User.schema.extract('mail').required()
        });
      },
      get event () { return User.events.USER_MAIL_UPDATED; }
    },
    ASSIGN_ROLE: {
      checkPolicies: (request, _1, _2, {user: currentUser}) => when(request.roles && !hasAllRoles(request.roles)(currentUser.data.roles)).rejectWith(FORBIDDEN_POLICY("User can not be assigned to an unknown role")),
      get schema() {
        return Joi.object().keys({
          id: User.schema.extract('id').required(),
          roles: User.schema.extract('roles').required(),
        });
      },
      get event () { return User.events.ROLE_ASSIGNED; }
    },
    UPDATE_USER: {
      checkPolicies: (request, _1, _2, {user: currentUser}) => Promise.all([
        when(('mail' in request) && currentUser.data.id !== request.id).rejectWith(FORBIDDEN_POLICY("user email can only be edited by the own user")),
        when(!currentUser.isSuperAdmin() && notBelongs(currentUser, request.owners)).rejectWith(FORBIDDEN_POLICY("User can not be assigned to an unknown owner")),
        when(request.roles && !hasAllRoles(request.roles)(currentUser.data.roles)).rejectWith(FORBIDDEN_POLICY("User can not be assigned to an unknown role"))
      ]),
      get schema() { return User.schema.fork(['id'], schema => schema.required()).fork(['name', 'lastName'], schema => schema.trim().min(1)).fork(['preferences.notifications.emailEnabled'], s => s.default(false)).or('mail', 'phone', 'name', 'lastName', 'job', 'roles', 'owners', 'preferences'); },
      get event () { return User.events.USER_UPDATED; }
    },
    VERIFY_EMAIL: {
      roles : [],
      checkPolicies: (request, _1, _2, {user: currentUser}) => when(request.id !== currentUser.data.id && currentUser.data.id !== require('../../../config/users.server').config.admin.aggregate.id).rejectWith(FORBIDDEN_POLICY('Email can only be verified by own user')),
      get schema() { return Joi.object().keys({id: User.schema.extract('id').required()}); },
      get event () { return User.events.EMAIL_VERIFIED; }
    },
    SIGNIN: {
      roles: [Roles.anonymous.id],
      get schema() { return Joi.object().keys({id: User.schema.extract('id').required()}); },
      get event () { return User.events.USER_AUTHENTICATED; }
    },
    SIGNOUT: {
      roles: [],
      get schema() { return Joi.object().keys({id: User.schema.extract('id').required()}); },
      get event () { return User.events.USER_LOGOUT; }
    },
    GENERATE_API_KEY: {
      roles: [Roles.superAdmin.id],
      get schema() { return Joi.object().keys({ id: User.schema.extract('id').required() }); },
      event: (action, prevUser, { apiKey }) => {
        return User.events.API_KEY_GENERATED.new({
          id: action.data.id,
          apiKeys: [apiKey],
        });
      },
    },
    SET_SIGNATURE: {
      checkPolicies: (request, _1, _2, { user: currentUser }) => when(!isOwnerOfSignature(currentUser, request.id)).rejectWith(FORBIDDEN_POLICY("Signatures can only be saved for your own account. Please ensure you're saving the signature for your own account")),
      get schema() {
        return Joi.object().keys({
          id: User.schema.extract('id').required(),
          signature: Joi.string(), // Image in Base64
        });
      },
      event: (_action, snap, triggeredEvent) => {
        const currentEvent = User.events.SIGNATURE_SET.new({
          id: snap.aggregate.id,
        });
        return [currentEvent, triggeredEvent];
      },
    },
    DELETE_SIGNATURE: {
      checkPolicies: (request, _1, _2, { user: currentUser }) => when(!isOwnerOfSignature(currentUser, request.id)).rejectWith(FORBIDDEN_POLICY("Signatures can only be deleted for your own account. Please ensure you're deleting the signature for your own account")),
      get schema() {
        return Joi.object().keys({
          id: User.schema.extract('id').required(),
        });
      },
      event: (_action, snap, triggeredEvent) => {
        const currentEvent = User.events.SIGNATURE_DELETED.new({
          id: snap.aggregate.id,
        });
        return [currentEvent, triggeredEvent].filter(Boolean);
      },
    },
  },
  queries: {
    MAIL_EXISTS: {
      get schema() { return Joi.object().keys({mail: User.schema.extract('mail').required(), where: Joi.array().items(Joi.object().keys({
        field: Joi.string().required(), operator: Joi.string(), value: Joi.any()
      }))}); },
      newRequest: (user, metadata) => ({
        action   : User.queries.MAIL_EXISTS.new(user, metadata),
        depends  : []
      })
    },
    PHONE_EXISTS: {
      get schema() { return Joi.object().keys({phone: User.schema.extract('phone').required(), where: Joi.array().items(Joi.object().keys({
        field: Joi.string().required(), operator: Joi.string(), value: Joi.any()
      }))}); },
      newRequest: (user, metadata) => ({
        action   : User.queries.PHONE_EXISTS.new(user, metadata),
        depends  : []
      })
    },
    GET_USER_BY_MAIL: {
      schema: Joi.object({ mail: mail.required() }),
      newRequest: ({mail: email}, metadata) => ({
          action : User.queries.GET_USER_BY_MAIL.new({ mail: email }, metadata),
          depends: [],
      })
    },
    GET_USERS_BY_ROLES: {
      schema: Joi.object({
        roles: Joi.array().items(Joi.string().uri()).min(1).required() // TODO: add an option to match all or any. default any.
      }),
      newRequest: ({ roles }, metadata) => ({
          action : User.queries.GET_USERS_BY_ROLES.new({ roles }, metadata),
          depends: User.queries.LIST.newRequest({where: {field: 'data.roles', operator: 'array-contains-any', value: roles}}),
          transform: ([users]) => users
      })
    },
  }
};

User = withDefaults(require('./Role').default)(User);

export default User;