import Config from '../config';
import { Joi } from '../../../validation/rules';
import { withDefaults } from '../..';
import { NOT_EXIST_POLICY, checkDuplicatedID, when } from '../../policies';
import { Roles } from '../../../iam/roles';

const gtinCheckSum = (code) => {
  if (!code?.length)
    return '';

  const checkSum = [...code].reduce((sum, digit, idx) => sum + parseInt(digit) * (idx % 2 === 0 ? 1 : 3), 0);

  const lastSumDigit = parseInt(checkSum.toString().slice(-1));
  return (lastSumDigit && (10 - lastSumDigit)).toString();
}

let Authenticator = withDefaults()({
  checkSum: gtinCheckSum,
  context: Config.context,
  name   : 'Authenticator',
  schema : Joi.object().keys({
    type       : Joi.string().valid('totp', 'hotp'),
    label      : Joi.string().uri(),
    secret     : Joi.string(),
    description: Joi.string(),
    parameters : Joi.object({
      issuer : Joi.string(),
      user   : Joi.string().uri(),
      counter: Joi.number().min(0),
      digits : Joi.number().min(5),
      period : Joi.number().min(30).unit('seconds'),
      extra  : Joi.object().unknown()
    }),
    tokens: Joi.object().pattern(Joi.string(), Joi.object().keys({
      value: Joi.string()
    }).when('type', { is: 'hotp', 
      then     : Joi.object().keys({ attempts: Joi.number().positive().allow(0) }), 
      otherwise: Joi.object().keys({ until: Joi.string().isoDateWithOffset() })
    })),
  }),
  events: {
    AUTHENTICATOR_CREATED: { },
    AUTHENTICATOR_DELETED: { snapshot: () => ({ metadata: { deleted: true }}) },
    TTL_SET: { snapshot: (event) => ({ expireAt: event.metadata.expireAt && new Date(event.metadata.expireAt) }) },
    OTP_TOKEN_GENERATED  : { },
    OTP_TOKEN_VALIDATED  : { },
    OTP_TOKEN_EXTENDED   : { },
    VALIDATION_PARAMETERS_UPDATED: { },
  }
});

Authenticator = withDefaults()({
  ...Authenticator,
  commands: {
    CREATE_AUTHENTICATOR: {
      checkPolicies: (auth, _, executor) => checkDuplicatedID(auth, Authenticator, executor),
      schema: Joi.object().keys({
        label : Authenticator.schema.extract('label' ).required(),
        id    : Authenticator.schema.extract('id'    ).default((parent) => Authenticator.newURN(parent.label)), 
        secret: Authenticator.schema.extract('secret').default(_ => require('otplib').authenticator.generateSecret()),
        type  : Authenticator.schema.extract('type'  ).default('totp'),
        owners: Authenticator.schema.extract('owners').default((parent) => parent.label ? [parent.label] : undefined)
      })
      .when('.type', { is: 'hotp',
        then:      Joi.object().keys({ 
          parameters: Authenticator.schema.extract('parameters').keys({ 
            user   : Authenticator.schema.extract('parameters.user' ).required(),
            period : Joi.forbidden(),
            counter: Authenticator.schema.extract('parameters.counter').default(1) 
          }).required()
        }),
        otherwise: Joi.object().keys({ 
          parameters: Authenticator.schema.extract('parameters').keys({ 
            user   : Authenticator.schema.extract('parameters.user' ).required(),
            digits : Authenticator.schema.extract('parameters.digits').default(8),
            period : Authenticator.schema.extract('parameters.period').default(require('../../../security/otp').TOTP_DEFAULT_PERIOD_IN_SECS),
            counter: Joi.forbidden(),
            extra  : Authenticator.schema.extract('parameters.extra').required()
          }).required(),
          tokens    : Authenticator.schema.extract('tokens').default((parent) => {
            const digits = parent.parameters.digits;
            const period = parent.parameters.period;
            const secret = parent.secret;

            const newToken = period && secret ? require('../../../security/otp').newTOTP({secret, parameters: {digits, period}}) : undefined;
            return newToken ? {[newToken.value]: newToken} : {};
          })
        })
      }),
      event : Authenticator.events.AUTHENTICATOR_CREATED
    },
    GENERATE_OTP_TOKEN: {
      checkPolicies: (_req, auth) => when(auth === undefined).rejectWith(NOT_EXIST_POLICY("Provide an existing authenticator ID")),
      schema: Authenticator.schema.fork(['id'], schema => schema.required()),
      event : Authenticator.events.OTP_TOKEN_GENERATED
    },
    EXTEND_OTP_TOKEN: {
      checkPolicies: (_req, auth) => when(auth === undefined).rejectWith(NOT_EXIST_POLICY("Provide an existing authenticator ID")),
      schema: Authenticator.schema.fork(['id'], schema => schema.required()),
      event : Authenticator.events.OTP_TOKEN_EXTENDED
    },
    DELETE_AUTHENTICATOR: {
      schema: Authenticator.schema.fork(['id'], schema => schema.required()),
      event : Authenticator.events.AUTHENTICATOR_DELETED
    },
    UPDATE_VALIDATION_PARAMETERS: {
      roles: [Roles.superAdmin.id],
      checkPolicies: (_req, auth) => when(auth === undefined).rejectWith(NOT_EXIST_POLICY("Provide an existing authenticator ID")),
      schema: Joi.object().keys({
        id: Authenticator.schema.extract('id').required(),
        validationParameters: Joi.object().unknown(),
      }),
      event: (action, authenticatorSnap, { extra }) => {
        return Authenticator.events.VALIDATION_PARAMETERS_UPDATED.new({
          id: action.data.id,
          parameters: {
            extra,
          },
        });
      },
    },
    SET_TTL: {
      schema: Joi.object().keys({
        id: Authenticator.schema.extract('id', schema => schema.required()),
        timestamp: Joi.date().timestamp('unix').required(),
      }),
      event: (action) => {
        return Authenticator.events.TTL_SET.new(action.data, { expireAt: action.data.timestamp.getTime() });
      },
    },
  },
  queries : {
    FIND_BY_LABEL: {
      schema : Authenticator.schema.fork(['label'], schema => schema.required()),
      newRequest: (auth, metadata) => ({
        action   : Authenticator.queries.FIND_BY_LABEL.new(auth, metadata),
        depends  : Authenticator.queries.LIST.newRequest({where: {field: 'data.label', operator: '==', value: auth.label}})
      })
    },
    FIND_BY_TOKEN: { // TODO: ugly.. this should never be executed directly in the client side.
      schema : Joi.object().keys({ value: Joi.number().integer().positive().required() }),
      newRequest: (token, metadata) => ({
        action   : Authenticator.queries.FIND_BY_TOKEN.new(token, metadata),
        depends  : Authenticator.queries.LIST.newRequest({where: {field: `data.tokens.${token.value}.value`, operator: '==', value: token.value}}), // retrieves only Active (non-deleted) authenticators
        transform: ([auths]) => { // TODO: this should be a query handler
          if (!auths?.length) { 
            console.log('[FIND_BY_TOKEN] token not found', token);
            return undefined; 
          }
          console.log('[FIND_BY_TOKEN] token found', token);

          try {
            require('assert').ok(auths.length === 1, `OTP token collision detected. Two or more active Authenticators share the same token '${token}'`);
            
            const { data: auth }         = auths.shift(),
                  { tokens, ...options } = auth;
                  
            auth.isValid = require('../../../security/otp').verify(options)(tokens[token.value]);
            
            return auth;

          } catch (e) {
            require('../../../errors').reportError(e);
            return undefined;
          }
        }
      })
    },
  }
});

export default Authenticator;