import { Authenticator, User } from 'services/server/functions/model/authentication/model';
import { Study } from 'services/server/functions/model/diagnosis/model';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import EventsLog from 'ui/components/EventsLog';

export default ({defaultSortOrder='asc', filter=_=>true, ...props}) => {
    const [currentUser] = useCurrentUser();
    if (('id' in props && !props.id) || !currentUser?.isSuperAdmin) return <></>;

    let {id, ...otherProps} = props;
    const isInstance = id !== undefined;
    const isTest  = id?.includes('/Test');
    const isStudy = isInstance && !isTest;

    id ??= Study.qualifiedName;

    const eventsQuery = {};
    if (isInstance) {
        eventsQuery.events = [[['aggregate.id', 'starts-with', id]]];
        if (isStudy) eventsQuery.events.push([['aggregate.id', '==', Authenticator.newURN(id)]], [['aggregate.id', '==', User.newURN(id)]]);
        eventsQuery['client-events'] = [[['data.id', 'starts-with', id]]];
    } else {
        eventsQuery.events = [[['aggregate.name', '==', Study.name]], [['aggregate.name', '==', Study.entities.Test.name]]];
    }

    const testIdFrom = (evt) => {
        const sequence = evt?.data.sequence || evt?.data.id.split('/Test/')[1]?.split('-')[0];
        const repeat = evt?.data.retry || evt?.data.id.split('/Test/')[1]?.split('-')[1];
        return sequence !== undefined ? `${parseInt(sequence) + 1}${parseInt(repeat) > 0 ? `R${repeat}` : ''}` : '-';
    }

    return <EventsLog 
        query={eventsQuery} 
        filter={(row, evt, events) => filter(row, evt, events)
                    //&& (evt.aggregate.name !== Study.name || Study.entities.Test.events[evt.type] === undefined) // Avoid repeated events due to Test events that are eventually repeated at study level because of legacy code in Study model
        }
        head={{
            night: {content: 'Night', hidden: !isStudy, align: 'center', formatter: (_cell, row) => testIdFrom(row.raw)},
            id:    {content: 'Study ID', hidden: isInstance, align: 'center', formatter: (_c, r) => r.id.includes('/Test/') ? r.id.split('/Test/')[0].split('/').pop() + '\nNight: ' + testIdFrom({data: {id: r.id}}) 
                                                                                                                         : r.id.split('/').pop() + (r.raw.data.patient?.id ? `\nPatient: ${r.raw.data.patient?.id}` : '') + (Study.getReference(r.raw.data) ? `\nRef: ${Study.getReference(r.raw.data)}` : '')},
            date: {sortValue: (_cell, row) => row.raw.seq},
            user: {formatter: (_cell, row) => row.user.metadata?.impersonator ? `Patient: ${row.user.data?.name}` : row.user.data?.mail || 'N/A'},
            causation: {hidden: !isTest}
        }}
        defaultSortOrder={defaultSortOrder}
        {...otherProps} 
    />;
}