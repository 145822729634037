import moment from 'moment';
import Modal, { CustomModal } from 'ui/acukit/ModalDialog';

import Details from 'ui/components/Details';
import DetailsBlock from 'ui/components/DetailsBlock';
import Spinner from '@atlaskit/spinner';
import TestStatusLegend from '../../components/TestStatusLegend';
import ReportMessages from '../ReportMessages';
import { WarningIcon } from 'ui/components/WarningIcon';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { Study } from 'services/server/functions/model/diagnosis/model';
import { Test } from 'services/server/functions/model/diagnosis/model/Test';
import useCurrentUser from 'ui/hooks/useCurrentUser';
import i18n from 'services/server/functions/i18n';
import { useRef, useState } from 'react';
import { Text } from 'ui/components/Text';

import './style.css';
import HelpTooltip from 'ui/components/HelpTooltip';
import useSnapshot from 'ui/hooks/useSnapshot';

const EditCommentsModal = (props) => {
  const [value, setValue] = useState(props.value);
  const saved = useRef(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onSave = _ => props.onSave({ id: `${props.studyId}/Test/${props.testId}-${props.retry || 0}`, sequence: props.testId, retry: props.retry || 0, clinicianComments: value }).then(true);
  
  return <>
    <CustomModal  id={'editComments'}
                  asLink
                  heading={Text.resolve('ViewTest.clinician-comments')}
                  label={props.label}
                  title={props.title}
                  showError={false}
                  cancelLabel={Text.resolve("global.button.close")}
                  secondaryLabel={Text.resolve("global.button.save")}
                  secondaryAction={() => { saved.current = true; onSave() }}
                  onToggle={e => {
                    const newValue = value || "";
                    const defaultValue = props.value || "";
                    if (!e && !saved.current && newValue !== defaultValue) setShowConfirmModal(true); 
                    else if (!e) setValue(defaultValue);

                    saved.current = false;
                  }}
                  appearance={props.appearance}
    >
      <textarea className="field" name="comments" placeholder={props.placeholder} value={value} onChange={(e) => setValue(e.target.value)}/>
    </CustomModal>
    <Modal show={showConfirmModal} dontCloseOnClick onClose={() => {setShowConfirmModal(false);}} heading="unsaved-changes-title" actions={[
        { text: 'unsaved-changes-discard',  onClick: () => {setValue(props.value); setShowConfirmModal(false);} },
        { text: 'unsaved-changes-save',  onClick: () => {onSave(); setValue(props.value); setShowConfirmModal(false);}, appearance: 'primary' },
    ]}>
        <span>{Text.resolve('unsaved-changes-content')}</span>
    </Modal>
  </>
};

const InfoModal = (props) => {
  return(
    <CustomModal  id={props.id || 'info'}
                  asLink
                  message={''}w
                  heading={Text.resolve(props.title || '')}
                  cancelLabel={Text.resolve("global.button.close")}
                  secondaryLabel={Text.resolve("global.button.save")}
                  label={Text.resolve(props.label || 'show')}
    >
      <div className="grid-row">
        {props.info && props.info.split ? props.info.split('\n').map((l, i) => <p key={`info${i}`}>{l}</p>) : props.info}
      </div>
    </CustomModal>
  )
};

const DetailsInfo = ({title, content}) => <Details label={Text.resolve(title)} value={content} />;


export const ViewSummary = ({ test, notify, dismiss, editComments, study, selectedRetry, selectedTest, children }) => {
    const [currentUser] = useCurrentUser();
    const hasAccess = currentUser.hasAccess;
    const { id, date, patient = {}, requestedTests } = study;
    const patientID = patient.id || "-";

    const { patientComments = undefined, clinicianComments = undefined, recording = {}, report = {}, status } = test;
    const isAnalised = status === Test.STATUS.analysed;
    
    const patientBirth = patient.birthDate ? moment(patient.birthDate, "DD/MM/YYYY").format("L") : "-";
    const startTime = recording.startTime ? moment(recording.startTime).utcOffset(recording.startTime).format("hh:mm a") : "-";
    const endTime = recording.endTime ? moment(recording.endTime).utcOffset(recording.endTime).format("hh:mm a") : "-";
    const conductedDate = Study.conductedDate({ [selectedTest]: test });
    const recordingDate = conductedDate ? moment(conductedDate).utcOffset(conductedDate).format("L") : "-";
    const studyDate = date ? moment(date).format("L") : "-";
    const length = recording.length ? moment().startOf('day').milliseconds(recording.length * 1000).format('H[h] m[m]') : "-";
    const validTime = recording.diagnosableTime ? moment().startOf('day').milliseconds(recording.diagnosableTime * 1000).format('H[h] m[m]') : "-";
    const severity = hasAccess(Study.entities.Test.queries.GET_ACUAHI3_SEVERITY) ? report.diagnosis?.['ACU-AHI3']?.severity : hasAccess(Study.entities.Test.queries.GET_ACUAHI4_SEVERITY) ? report.diagnosis?.['ACU-AHI4']?.severity : undefined;
    const canAccessDiagnosisReport = hasAccess(Study.entities.Test.queries.VIEW_DIAGNOSIS_REPORT);
    const utc = (recording.startTime || recording.endTime) ? moment(recording.startTime || recording.endTime).utcOffset(recording.startTime || recording.endTime).format("Z") : undefined;
    const timezone = recording.timezone;
    const timezoneStr = (utc || timezone) ? `${i18n.resolve("Timezone", {}, { component: "UtcTimeZone" })}: ${utc ? '(GMT ' + utc + ')' : ''} ${timezone ? i18n.resolve(timezone, {}, { component: "UtcTimeZone" }) : ''}` : undefined;
    const nigth = parseInt(selectedTest) + 1;
    const numberOfNight = selectedRetry ? `${nigth} [R${selectedRetry}] ` : nigth;
    const nighDetails = Text.resolve('n_of_m', { n: numberOfNight, m: requestedTests || '-' });

    const PatientComments = ({ helpPosition = 'top' }) => <Details id="studyPatientComments" label="ViewTest.details.patient-comments" helpPosition={helpPosition} help={"ViewTest.details.patient-comments-help"} valueClass='preserve-line-breaks' value={patientComments ? <InfoModal info={<DetailsInfo title={"ViewTest.details.patient-comments"} content={patientComments} />} label={'view'} /> : '-'} />;

    const ClinicianCommentDetails = () =>
        <Details id="clinicianComments" label="ViewTest.clinician-comments"
            valueClass='preserve-line-breaks'
            value={
                hasAccess(Study.entities.Test.commands.COMMENT_TEST) ?
                    <EditCommentsModal title="title" label={Text.resolve(clinicianComments ? 'view' : 'add')} placeholder={Text.resolve('ViewTest.clinician-comments.placeholder')} appearance={'primary'} value={clinicianComments} onSave={editComments} studyId={id} testId={selectedTest} retry={selectedRetry} />
                    : clinicianComments ? <InfoModal info={<DetailsInfo title={"ViewTest.clinician-comments"} content={clinicianComments} />} label={'view'} /> : '-'}
        />

    const NOT_VALID_DATA_CODE = 'AC/WARNING/301';
    const hasRepeatedTest = (isAnalised || test?.repeatReason?.includes(Test.REPEAT_REASONS.sensorFellOff)) && test?.repeated;
    const notValidDataError = test?.report?.messages?.find(m => m.code === NOT_VALID_DATA_CODE);
    const excludeMessages = [NOT_VALID_DATA_CODE];
    const notValidDataEmbedNotification = notValidDataError && isAnalised && (
        <>
            <DetailsBlock inline className="warning" contentClass="content">
                <WarningIcon />
                <div className="vcentered">
                    <span className="wanrContent"><Text>Diagnosis based on 2-4h valid data.</Text></span>
                </div>
            </DetailsBlock>
        </>
    );

    const statusClass = hasRepeatedTest ? 'REPEATED' : Test.getStatusGroup(test.status);
    const statusText = hasRepeatedTest ? 'module.diagnosis.fe.study.repeated_label' : Test.getStatusGroup(test.status);
    const diagnosisRef = useRef(null);

    const onClickDiagnosisResult = () => diagnosisRef?.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

    const criteriaValue = hasAccess(Study.entities.Test.queries.GET_ACUAHI3_SEVERITY) ? 3 : 4;

    const Marker = Study.entities.Test.entities.Marker;
    const [_markers ] = useSnapshot(Marker, {owner: test?.id, autoStart: Boolean(test?.id)})
    const markers = Object.values(_markers || {}).map(m => m.data);
    const hasScoring = Boolean(Marker.toManualScore(markers, test?.recording?.diagnosableTime));
  
    return <>
        <div className="pageContent" id="ViewSummary">
            <div className="pageSection">
                <div className="title"><Text>ViewTest.details.title</Text></div>
                <DetailsBlock hidden={!hasScoring} inline className="warning" contentClass="content">
                    <div className="icon"><InfoIcon size='xlarge' /></div>
                    <div>
                        <span className="wanrContent">{Text.resolve("module.diagnosis.fe.view.test.manually_scored_message")}</span>
                        <b className="wanrContent">{" " + Text.resolve("module.diagnosis.fe.view.test.manually_scored_message_2")}</b>
                        <span className="wanrContent">{" " + Text.resolve("module.diagnosis.fe.view.test.manually_scored_message_3")}</span>
                    </div>
                </DetailsBlock>
                <DetailsBlock className="studyDetailsHeader-blue testSummaryHeader">
                    <div id="patientDetails">
                        <div className="grid-col">
                            <DetailsBlock className="profileBlock">
                                <div className="grid-col">
                                    <Details id="studyPatientID" label="patient-id" value={patientID.toUpperCase()} />
                                    <Details id="studyPatientBirth" label="global.date_of_birth" value={patientBirth} className="canBeHiddenAt410" />
                                </div>
                            </DetailsBlock>
                        </div>
                    </div>
                    <div id="patientProfile">
                        <div className="grid-col">
                            <DetailsBlock className="feedbackBlock">
                                <div className="grid-col">
                                    <Details id="night" label="ViewStudy.night" value={nighDetails} />
                                    <Details id="studyDate" label="date-created" value={studyDate} />
                                    <Details id="studyStartTime" label="ViewTest.details.start-time" value={startTime} help={timezoneStr} helpPosition="top" />
                                    <Details id="studyLength" label="ViewTest.details.recording-length" value={length} />
                                </div>
                                <div className="grid-col">
                                    <Details id="testStatus" label="status" helpPosition='right' help={<TestStatusLegend />} value={<div className={`status ${statusClass}`}>{Text.resolve(statusText, {}, { schema: "diagnosis.Study.Test" })}</div>} />
                                    <Details id="recordingDate" label="global.date_conducted" value={recordingDate} />
                                    <Details id="studyEndTime" label="ViewTest.details.end-time" value={endTime} help={timezoneStr} helpPosition="top" />
                                    <Details id="validTime" label="ViewTest.details.valid-time" value={validTime} help="ViewTest.details.valid-time.help" />
                                </div>
                                <div className="grid-col">
                                    <PatientComments />
                                    <ClinicianCommentDetails />
                                    <div />
                                    <div />
                                </div>
                            </DetailsBlock>
                        </div>
                    </div>
                </DetailsBlock>
            </div>
            <div className="pageSection">
                <div className="title"><Text>ViewTest.test-result</Text></div>
                {!status ? <DetailsBlock contentClass='placeholder'><Text>not-available</Text></DetailsBlock>
                    :
                    <>
                        {!hasRepeatedTest && notValidDataEmbedNotification}
                        {
                            hasRepeatedTest ?
                                <>
                                    <DetailsBlock inline className="warning" contentClass="content">
                                        <WarningIcon />
                                        <div className="grid-row">
                                            <span className="title"><Text>{`module.diagnosis.fe.view.test.repeat_reason_summary.${test?.repeatReason?.[0] || Test.REPEAT_REASONS.sensorFellOff}`}</Text></span>
                                            <span className="wanrContent"><Text>{`module.diagnosis.fe.view.test.repeat_reason_warn.${status === Test.STATUS.analysed && test?.repeatReason?.includes(Test.REPEAT_REASONS.sensorFellOff) ? 'VALID-' : ''}${test?.repeatReason?.[0] || Test.REPEAT_REASONS.sensorFellOff}`}</Text></span>
                                        </div>
                                    </DetailsBlock>
                                    {notValidDataEmbedNotification}
                                </>
                                :
                                Test.STATUS.compare(status, Test.STATUS.analysed) < 0 ?
                                    <DetailsBlock inline className="warning" contentClass="content">
                                        <WarningIcon />
                                        <div className="grid-row">
                                            <span className="title"><Text>{`ViewTest.status.${status.toLowerCase()}`}</Text></span>
                                            <span className="wanrContent"><Text>{`ViewTest.status.${status.toLowerCase()}.desc`}</Text></span>
                                            {status === Test.STATUS.conducted && <span className="centered"><Spinner size="large" /></span>}
                                        </div>
                                    </DetailsBlock>
                                    : <>
                                        {(status === Test.STATUS.invalid || severity) && <DetailsBlock inline className={`testResult ${severity} ${status}`}>
                                            <div className="grid-row">
                                                <div id="diagnosis-result" className={`resultSummary centered vcentered ${isAnalised && canAccessDiagnosisReport ? 'clickable' : ''}`} onClick={onClickDiagnosisResult}>
                                                    <div className={`testSummary ${status === Test.STATUS.invalid ? 'invalid' : severity.replace('/', '_').toLowerCase()}`}>
                                                        {status !== Test.STATUS.invalid && ["normal", "normal/mild", "mild"].includes(severity.toLowerCase()) ? Text.resolve("ViewTest.summary.diagnosis.no-apnoea")
                                                            : status !== Test.STATUS.invalid && ["mild/moderate", "moderate", "moderate/severe", "severe"].includes(severity.toLowerCase()) ? Text.resolve("The patient has %{severity} sleep apnoea", { severity: Text.resolve(severity) })
                                                                : Text.resolve("ViewTest.summary.diagnosis.insufficient-info")}
                                                    </div>
                                                </div>
                                                {status !== Test.STATUS.invalid && <div className="resultFooter">
                                                    <span className='asm-text'>
                                                        <Text variables={{
                                                            value: criteriaValue
                                                        }}>
                                                            {'ViewTest.summary.asm-criteria'}
                                                        </Text>
                                                    </span>
                                                    <HelpTooltip
                                                        variables={{
                                                            value: criteriaValue
                                                        }}
                                                        content="ViewTest.summary.asm-criteria.tooltip"
                                                        id="asm-criteria" />
                                                </div>}
                                                <ReportMessages status={status} report={report} currentUser={currentUser} notify={notify} dismiss={dismiss}
                                                    exclude={excludeMessages} />
                                            </div>
                                            <div className="resultImage"></div>
                                        </DetailsBlock>}
                                    </>
                        }
                    </>
                }
                
            </div>
            
        </div>
        {
            isAnalised && canAccessDiagnosisReport &&
            <div className="diagnosis-children" ref={diagnosisRef}>{children}</div>
        }
    </>;
};