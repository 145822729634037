export const TOTP_DEFAULT_PERIOD_IN_SECS = 2592000; // secs === 30 days

// TODO add support for HOTP ?
export const generateSecret = (...args) => require('otplib').authenticator.generateSecret(...args);
export const newTOTP = (options) => {
  const { type='totp', secret=generateSecret(), parameters={} } = options || {},                 // if secret not provided, then we return a new random secret used to generate the token.
        { period=TOTP_DEFAULT_PERIOD_IN_SECS, digits=8 }        = parameters,
        step                                                    = (period < 86400) ? 30: 86400;  // 30 secs or 1d = 86400 secs

  require('assert').equal(type, 'totp', `Unsuported token type = '${type}'`);

  const totp = require('otplib').totp;
  totp.options = { digits, step, epoch: Date.now(), "window": [Math.max(0, Math.round(period/step)), 0] };

  const token = { until: new Date(totp.options.epoch + period*1000).toISOString() };
  token.value = totp.generate(secret); 

  return options?.secret !== undefined ? token : { token, options: { type, secret, parameters: { period } } };
}

export const extendOTP = (options={}) => (token) => {
  const { type='totp', parameters={}         } = options,
        { period=TOTP_DEFAULT_PERIOD_IN_SECS } = parameters;

  require('assert').equal(type, 'totp', `Unsuported token type = '${type}'`);

  const extended = {...token};
  extended.until = new Date(Date.now() + period*1000).toISOString(); // UGLY: not very orthodox 
  
  return extended;
}

export const verify = (options={}) => (token) => {
  const { type='totp', secret, parameters={} } = options,
        { period=TOTP_DEFAULT_PERIOD_IN_SECS } = parameters,
        { value, until                       } = token,
        step                                   = (period < 86400) ? 30: 86400;  // 30 secs or 1d = 86400 secs

  require('assert').equal(type, 'totp', `Unsuported token type = '${type}'`);

  const totp = require('otplib').totp;
  totp.options = {
    step, 
    digits  : value.length,
    epoch   : Date.now(),
    "window": [Math.max(0, Math.round(period/step)), 0] // TODO: does this actually makes sense? why is this not just 0 ?
  };

  return totp.check(value, secret) || Date.now() < new Date(until).getTime();  // FIXME: jest is not mocking properly the system date ? seem tests fail
}

export const timeUsed = () => { throw new Error('TODO: Not Implemented') };
export const timeRemaining = () => { throw new Error('TODO: Not Implemented') };